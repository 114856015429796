import React from 'react'
import styled, { css } from 'styled-components'
import { FieldProps } from 'formik'

import useLocation from 'hooks/useLocation'

type Props = {
  placeholder?: string
  required?: boolean
  disabled?: boolean
  readonly?: boolean
  textarea?: boolean
}

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: 2rem;
  ${({ theme }) => theme.media.lg.min} {
    width: 30%;
    margin-bottom: 3rem;
  }
`

const Input = styled.input<{ error: boolean; secondary?: boolean }>`
  box-sizing: border-box;
  width: 100%;
  height: 45px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.secondary};
  font-size: 18px;
  color: ${({ theme }) => theme.colors.dark};

  &:focus,
  &:active {
    outline: none !important;
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.gray};
  }

  ${({ error }) =>
    error &&
    css`
      border-color: ${({ theme }) => theme.colors.danger};
    `}

  ${({ secondary }) =>
    secondary &&
    css`
      ::placeholder {
        color: ${({ theme }) => theme.colors.dark200};
        opacity: 1;
      }
    `}
    

  ${({ theme }) => theme.media.lg.min} {
    max-width: 355px;
  }
`

const Textarea = styled.textarea<{ secondary?: boolean }>`
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  margin-bottom: 2rem;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.secondary};
  font-size: 18px;
  color: ${({ theme }) => theme.colors.dark};
  resize: none;

  &:focus,
  &:active {
    outline: none !important;
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.gray};
  }

  ${({ secondary }) =>
    secondary &&
    css`
      ::placeholder {
        color: ${({ theme }) => theme.colors.dark200};
        opacity: 1;
        line-height: normal;
      }
    `}

  ${({ theme }) => theme.media.lg.min} {
    width: 100%;
    margin-bottom: 3rem;
    max-width: 1305px;
  }
`

const ErrorMessage = styled.p<{ secondary?: boolean }>`
  margin-top: -0.75rem;
  margin-bottom: 0.75rem;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.danger};
  text-align: left;

  ${({ theme }) => theme.media.lg.min} {
    ${({ secondary }) =>
      !secondary &&
      css`
        position: absolute;
        top: -25px;
        left: 0;
        margin: 0;
      `}
  }
`

const FormInput: React.FC<Props & FieldProps> = ({
  field: { name, onChange, onBlur, value },
  form: { touched, errors },
  required,
  placeholder,
  disabled,
  readonly,
  textarea,
}) => {
  const errorText = errors[name] && touched[name] && errors[name]
  const pathname = useLocation()

  return textarea ? (
    <Textarea
      id={name}
      name={name}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      disabled={disabled}
      readOnly={!!readonly}
      required={required}
      secondary={pathname !== '/'}
    />
  ) : (
    <InputWrapper>
      {!!errorText && (
        <ErrorMessage secondary={pathname !== '/'}>{errorText}</ErrorMessage>
      )}
      <Input
        id={name}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        disabled={disabled}
        readOnly={!!readonly}
        required={required}
        error={!!errorText}
        secondary={pathname !== '/'}
      />
    </InputWrapper>
  )
}

export default FormInput
