import * as Yup from 'yup'

export const ASK_ABOUT_FLAT_FORM_SCHEMA = Yup.object().shape({
  name: Yup.string().required(`Proszę o podanie imienia`),
  email: Yup.string()
    .email(`Adres email jest nieprawidłowy`)
    .required(`Proszę podać adres e-mail`),
  phone: Yup.string()
    .min(9, `Numer telefonu jest nieprawidłowy`)
    .matches(
      /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i,
      `Numer telefonu jest nieprawidłowy`,
    )
    .required(`Proszę podać numer telefonu`),
  agree1: Yup.bool().oneOf([true]).required(),
  agree2: Yup.bool().oneOf([true]).required(),
})

export const ASK_ABOUT_FLAT_INIT_VALUES = {
  name: '',
  phone: '',
  email: '',
  message: '',
  agree1: false,
  agree2: false,
}

export type AskAboutFlatValues = {
  name: string
  phone: string
  email: string
  message: string
  agree1: boolean
  agree2: boolean
}
