import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import LazyImage from 'components/shared/lazyImage'
import Icon from 'components/shared/icon'
import { Heading, Text } from 'components/shared/typography'

import patternIcon from 'assets/icons/pattern-1.svg'
import homeIcon from 'assets/icons/home.svg'
import phoneIcon from 'assets/icons/phone.svg'
import inDigitalWhite from 'assets/icons/indigital-white.svg'
import facebookIcon from 'assets/icons/facebook.svg'

import CopyrightBar from 'components/layout/Footer/CopyrightBar'

import { ImageDataLike } from 'gatsby-plugin-image'

import useBreakpoint from 'hooks/useBreakpoint'

type Props = {
  cloudImg: ImageDataLike
  logo: ImageDataLike
}

const Wrapper = styled.footer`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.dark100};
  overflow: hidden;
`

const HeaderWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 20px;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 3;
`

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 120px;
  padding-bottom: 50px;

  ${({ theme }) => theme.media.lg.min} {
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    padding-top: 200px;
    padding-bottom: 60px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    justify-content: flex-end;
    padding-top: 0;
  }
`

const Contact = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 360px;
  margin-bottom: 20px;

  ${({ theme }) => theme.media.lg.min} {
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    max-width: 1100px;
    margin-bottom: 0;

    ${Heading} {
      align-self: flex-start;
      br {
        display: none;
      }
    }
  }
`

const ContactList = styled.ul`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`

const ContactListItem = styled.li`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 30px;

  // Icon Wrapper
  & > div {
    margin-right: 24px;
  }

  ${({ theme }) => theme.media.lg.min} {
    margin-bottom: 20px;

    :last-child {
      margin-bottom: 0;
    }
  }
`

const OpeningHours = styled.div``

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 20px;
  max-width: 340px;

  ${({ theme }) => theme.media.lg.min} {
    margin-bottom: 0;
    max-width: 220px;
  }
`

const Author = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;

  & > :last-child {
    margin-top: 5px;
  }
`

const FacebookLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;

  ${({ theme }) => theme.media.lg.min} {
    position: absolute;
    bottom: 0;
    left: 0;
  }
`

const PatternWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 300px;
  margin-left: 60px;
  z-index: 0;

  ${({ theme }) => theme.media.xxl.min} {
    margin-left: 150px;
  }
`

const CloudWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-40%, -35%);
  width: 1100px;
  z-index: 2;

  ${({ theme }) => theme.media.sm.min} {
    width: 1250px;
  }

  ${({ theme }) => theme.media.lg.min} {
    width: 1400px;
    transform: translate(-18%, -35%);
  }

  ${({ theme }) => theme.media.lg.min} {
    transform: translate(-18%, -55%);
  }

  ${({ theme }) => theme.media.xxl.min} {
    width: 1600px;
    transform: translate(-11%, -45%);
  }
`

const CloudWrapper2 = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-55%, -35%);
  width: 1100px;
  z-index: 2;

  ${({ theme }) => theme.media.sm.min} {
    width: 1250px;
  }

  ${({ theme }) => theme.media.lg.min} {
    width: 1400px;
    transform: translate(-29%, -35%);
  }

  ${({ theme }) => theme.media.lg.min} {
    transform: translate(-29%, -55%);
  }

  ${({ theme }) => theme.media.xxl.min} {
    width: 1600px;
    transform: translate(-19%, -47%);
  }
`

const CloudWrapper3 = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-65%, -35%);
  width: 1100px;
  z-index: 2;

  ${({ theme }) => theme.media.sm.min} {
    width: 1250px;
  }

  ${({ theme }) => theme.media.lg.min} {
    width: 1400px;
    transform: translate(-44%, -35%);
  }

  ${({ theme }) => theme.media.lg.min} {
    transform: translate(-44%, -55%);
  }

  ${({ theme }) => theme.media.xxl.min} {
    width: 1600px;
    transform: translate(-49%, -48%);
  }
`

const CloudWrapper4 = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-105%, -35%);
  width: 1400px;
  z-index: 2;

  ${({ theme }) => theme.media.lg.min} {
    transform: translate(-105%, -55%);
  }

  ${({ theme }) => theme.media.xxl.min} {
    width: 1600px;
    transform: translate(-85%, -48%);
  }
`

const CloudWrapper5 = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-114%, -35%);
  width: 1400px;
  z-index: 2;

  ${({ theme }) => theme.media.lg.min} {
    transform: translate(-114%, -55%);
  }

  ${({ theme }) => theme.media.xxl.min} {
    width: 1600px;
    transform: translate(-115%, -49%);
  }
`

const Footer: React.FC<Props> = ({ cloudImg, logo }) => {
  const { lg, xxl } = useBreakpoint()

  return (
    <Wrapper id="kontakt">
      <HeaderWrapper>
        {!lg && (
          <Container narrow>
            <Heading as="h4" size={38} weight={500} themecolor="dark">
              Skontaktuj <br />
              się z nami
            </Heading>
          </Container>
        )}
      </HeaderWrapper>
      <StyledContainer narrow={lg ? false : true}>
        <Contact>
          {/* {lg && (
            <FacebookLink
              href="https://www.facebook.com/borstar1"
              target="_blank"
            >
              <Icon src={facebookIcon} size={15} alt="facebook" />
            </FacebookLink>
          )} */}
          <ContactList>
            {lg && (
              <Heading as="h4" size={42} weight={500} themecolor="gray100">
                Skontaktuj <br /> się z nami
              </Heading>
            )}
            <ContactListItem>
              <Icon src={homeIcon} size={16} alt="home" />
              <Text
                size={16}
                themecolor="gray100"
                dangerouslySetInnerHTML={{
                  __html: 'Ul. Rynek 1, 38-600 Lesko',
                }}
              />
            </ContactListItem>
            <ContactListItem>
              <Icon src={phoneIcon} size={16} alt="phone" />
              <a href="tel: 530 566 000">
                <Text
                  size={16}
                  themecolor="gray100"
                  dangerouslySetInnerHTML={{ __html: '530 566 000' }}
                />
              </a>
            </ContactListItem>
            <ContactListItem>
              <Icon src={phoneIcon} size={16} alt="phone" />
              <a href="tel: 13 463 15 04">
                <Text
                  size={16}
                  themecolor="gray100"
                  dangerouslySetInnerHTML={{ __html: '13 463 15 04' }}
                />
              </a>
            </ContactListItem>
          </ContactList>
          <OpeningHours>
            <Text
              size={16}
              weight={600}
              themecolor="gray100"
              dangerouslySetInnerHTML={{
                __html: 'Godziny otwarcia biura:',
              }}
            />
            <Text
              size={15}
              themecolor="gray100"
              dangerouslySetInnerHTML={{
                __html: 'Poniedziałek – Piątek 8.00- 16.00',
              }}
            />
          </OpeningHours>
          <LogoWrapper>
            <LazyImage src={logo} alt="Wild-Estate-logo" />
          </LogoWrapper>
        </Contact>

        {!lg && (
          <FacebookLink
            href="https://www.facebook.com/borstar1"
            target="_blank"
          >
            <Icon src={facebookIcon} size={15} alt="facebook" />
          </FacebookLink>
        )}
        {!lg && (
          <Author>
            <Text size={15} themecolor="gray100">
              Projekt i Realizacja:
            </Text>
            <a
              href="https://landingpage.indigital.pl/"
              target="_blank"
              rel="nofollow"
            >
              <Icon src={inDigitalWhite} width={90} alt="indigital-logo" />
            </a>
          </Author>
        )}
        {xxl && (
          <PatternWrapper>
            <Icon src={patternIcon} auto alt="pattern" />
          </PatternWrapper>
        )}
      </StyledContainer>
      <CopyrightBar />
      <CloudWrapper>
        <LazyImage src={cloudImg} alt="cloud" />
      </CloudWrapper>
      <CloudWrapper2>
        <LazyImage src={cloudImg} alt="cloud" />
      </CloudWrapper2>
      <CloudWrapper3>
        <LazyImage src={cloudImg} alt="cloud" />
      </CloudWrapper3>
      {lg && (
        <CloudWrapper4>
          <LazyImage src={cloudImg} alt="cloud" />
        </CloudWrapper4>
      )}
      {lg && (
        <CloudWrapper5>
          <LazyImage src={cloudImg} alt="cloud" />
        </CloudWrapper5>
      )}
    </Wrapper>
  )
}

export default Footer
