import React from 'react'
import { Field } from 'formik'
import Checkbox from 'components/shared/Checkbox'

const Terms: React.FC = () => {
  const TERMS = [
    {
      id: 'agree1',
      label:
        'Wyrażam zgodę na przetwarzanie moich danych osobowych w celu obsługi zapytania lub przedstawienia oferty. Wyrażenie zgody jest dobrowolne, ale konieczne, abyśmy mogli kontaktować się z Państwem w celu obsługi zapytania i przedstawienia oferty. Jeżeli nie chcą Państwo, abyśmy kontaktowali się w tym celu za pomocą e-maila lub telefonu, zapraszamy do odwiedzenia najbliższego biura sprzedaży.*',
    },
    {
      id: 'agree2',
      label:
        'Wyrażam zgodę na wykorzystywanie przez Grupa Borstar J.D Borgosz Sp. J. telekomunikacyjnych urządzeń końcowych i automatycznych systemów wywołujących tj. telefon, poczta e-mail dla celów marketingowych w rozumieniu przepisów ustawy z dnia 16 lipca 2014 r. Prawo telekomunikacyjne.',
    },
  ]

  return (
    <>
      {TERMS.map((term) => (
        <Field
          key={term.id}
          type="checkbox"
          name={term.id}
          label={term.label}
          component={Checkbox}
        />
      ))}
    </>
  )
}

export default Terms
