/* eslint-disable */
import React, { useState } from 'react'
import { FieldProps } from 'formik'
import styled, { css } from 'styled-components'

type Props = {
  label: string
}

const SingleTerm = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.dark};
`

const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const Input = styled.input`
  align-self: flex-start;
  position: relative;
  width: 0;
  height: 0;
  visibility: hidden;
`

const Checkmark = styled.div<{ checked: boolean; error: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  height: 15px;
  min-height: 15px;
  width: 15px;
  min-width: 15px;
  margin-right: 1rem;
  margin-top: 3px;

  border: 1px solid ${({ theme }) => theme.colors.dark};

  ${({ checked }) =>
    checked &&
    css`
      padding: 3px;
      background-color: ${({ theme }) => theme.colors.light};
      background-clip: content-box;
    `}

  ${({ error }) =>
    error &&
    css`
      border-color: ${({ theme }) => theme.colors.danger};
    `}
`

const LabelText = styled.p<{ expanded: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  padding-right: 10px;
  font-size: 13px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: ${({ theme }) => theme.colors.dark};

  a {
    color: ${({ theme }) => theme.colors.dark};
  }

  ${({ expanded }) =>
    expanded &&
    css`
      display: block;
      text-overflow: clip;
      -webkit-line-clamp: none;
    `}

  ${({ theme }) => theme.media.lg.min} {
    -webkit-line-clamp: 1;
  }
`

const Button = styled.button`
  align-self: flex-start;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.dark};
  text-decoration: underline;
  cursor: pointer;
`

const FormCheckbox: React.FC<Props & FieldProps> = ({
  field: { name, onChange, onBlur, value },
  form: { touched, errors },
  label,
}) => {
  const [expanded, setExpaned] = useState(false)

  const error = !!(touched[name] && errors[name])

  return (
    <SingleTerm>
      <Label htmlFor={name}>
        <Checkmark checked={value} error={error}></Checkmark>
        <Input
          id={name}
          name={name}
          type="checkbox"
          onChange={onChange}
          onBlur={onBlur}
          checked={value}
        />
        <LabelText
          dangerouslySetInnerHTML={{ __html: label }}
          expanded={expanded}
        />
      </Label>
      <Button type="button" onClick={() => setExpaned(!expanded)}>
        <p>{expanded ? 'Zwiń' : 'Rozwiń'}</p>
      </Button>
    </SingleTerm>
  )
}

export default FormCheckbox
